import React from 'react'
import { Figure } from './Figure'
import { Link } from 'gatsby'

export const serializers = {
  types: {
    authorReference: ({ node }: { node: any }) => (
      <span>{node.author.name}</span>
    ),
    mainImage: Figure,
  },
  marks: {
    internalLink: ({ mark, children }: any) => {
      console.log('MARK', mark)
      const { slug = {} } = mark
      const href = `/${slug.current}`
      return <Link to={href}>{children}</Link>
    },
  },
}
